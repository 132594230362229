<template>
  <div>
    <v-text-field outlined v-if="confKeyObject.type === 'string'" v-model="userVal" :label="label"/>
    <v-text-field outlined v-if="confKeyObject.type === 'email'" confKeyObject.type="email" v-model="userVal" :label="label"/>
    <v-text-field outlined v-else-if="confKeyObject.type === 'number'" confKeyObject.type="number" v-model="userVal" :label="label"/>
    <div v-else-if="confKeyObject.type === 'boolean'">
      <v-switch v-model="userVal" :label="label + (label ? ' - ' : '') + (userVal ? 'vrai' : 'faux')"/>
    </div>
    <v-autocomplete outlined v-else-if="confKeyObject.type === 'enum'" :items="confKeyObject.values" v-model="userVal" :label="label"/>
    <div v-else-if="confKeyObject.type === 'date'">
      <span v-if="label">{{ label }}</span>
      <v-date-picker v-model="userVal"/>
    </div>
    <div v-else-if="confKeyObject.type === 'time'">
      <span v-if="label">{{ label }}</span>
      <v-time-picker v-model="userVal" format="24hr"/>
    </div>
  </div>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";

export default {
  name: "LicenseConfKeyInput",
  data: () => ({
    userVal: null,
    confKeyObject: null
  }),
  props: ['value', 'confKey', 'showLabel'],
  created() {
    this.updateVal(this.value)
    this.updateConfKey(this.confKey);
  },
  watch: {
    value(newVal) {
      this.updateVal(newVal);
    },
    userVal(newVal) {
      this.$emit('input', newVal);
    },
    confKey(key) {
      this.updateConfKey(key);
    }
  },
  computed: {
    label() {
      return this.showLabel === true ? this.confKeyObject.label : '';
    }
  },
  methods: {
    updateVal(val) {
      /**
      if (this.confKeyObject && this.confKeyObject.type === 'boolean') {
        this.userVal = val + '';
      } else {
        this.userVal = val;
      }
       */
      this.userVal = val;
    },
    updateConfKey(key) {
      this.confKeyObject = LicenseBusiness.getKeysMap()[key];
    }
  }
}
</script>

<style scoped>

</style>
