<template>
  <v-data-table style="width: 100%" :items="branchesWithUsableConf" :headers="dynamicHeaders" :options.sync="options"
                @click:row="branchClicked">
    <template v-slot:item.shopName="{item}">
      <span>{{ item.shopName }}<span v-if="item.name"> ({{ item.name }})</span><span v-if="item.yodatechName"
                                                                                     class="text--disabled"> {{
          item.yodatechName
        }}</span></span><br/>
      <pre class="grey--text smol-text">{{ item.uuid }}</pre>
    </template>
    <template v-slot:item.companyName="{item}">
      <span>{{ item.companyName }}</span><br/>
      <pre class="grey--text smol-text">{{ item.companyUuid }}</pre>
    </template>
    <template v-slot:item.licenseSupportStatus="{item}">
      <div class="smol-text one-line">
        Support
        <v-icon small :color="item.licenseSupportStatus | colorForStatus" aria-hidden="false">
          {{ item.licenseSupportStatus | iconForStatus }}
        </v-icon>
      </div>
      <div class="smol-text one-line">
        Licence
        <v-icon small :color="item.licenseActivationStatus | colorForStatus" aria-hidden="false">
          {{ item.licenseActivationStatus | iconForStatus }}
        </v-icon>
      </div>
    </template>
    <template v-slot:item.license.conf.cloud_actif="{item}">
      <v-icon :color="item.license.conf['cloud.actif'] === 'true' ? 'light-blue' : 'grey lighten-1'">mdi-cloud</v-icon>
    </template>
    <template v-slot:item.license.conf.ea_actif="{item}">
      <v-icon :color="item.license.conf['ea.actif'] === 'true' ? 'amber' : 'grey lighten-1'">mdi-star</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";
import semVerCompare from "semver-compare"

const SEM_VER_REGEX = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/.compile();

export default {
  name: "BranchTable",
  props: ['branches', 'confToShow'],
  filters: {
    ...LicenseBusiness.filters
  },
  data: () => ({
    headers: [
      {value: 'licenseSupportStatus', text: 'Status'},
      {value: 'license.conf.cloud_actif', text: 'Cloud'},
      {value: 'license.conf.ea_actif', text: 'EA'},
      {value: 'shopName', text: 'Agence'},
      {value: 'companyName', text: 'Société'},
      {
        value: 'yodaforexVersion', text: 'Version', sort: (v1, v2) => {
          if (SEM_VER_REGEX.test(v1) && SEM_VER_REGEX.test(v2)) {
            return semVerCompare(v1, v2)
          } else return v1 === v2 ? 0 : (v1 < v2 ? -1 : 1);
        }
      }
    ],
    options: {
      itemsPerPage: -1,
      multiSort: true
    }
  }),
  watch: {
    branches() {
      this.options.page = 1;
    }
  },
  computed: {
    dynamicHeaders() {
      let result = [...this.headers];
      if (this.confToShow) {
        for (let i = 0; i < this.confToShow.length; i++) {
          result.push({
            value: 'license.conf.' + this.confToShow[i].replaceAll('.', '_'),
            text: this.confToShow[i]
          })
        }
      }
      return result;
    },
    branchesWithUsableConf() {
      let result = [];
      for (let i = 0; i < this.branches.length; i++) {
        const b = this.branches[i];
        for (const confKey in b.license.conf) {
          b.license.conf[confKey.replaceAll('.', '_')] = b.license.conf[confKey];
        }
        result.push(b);
      }
      return result;
    }
  },
  methods: {
    branchClicked(b) {
      // L'objet qu'on retourne contient des clés de conf dupliquées avec des "_" au lieu des "."
      // C'est moche, mais toutes les méthodes tentées pour rendre l'objet "propre" ont été infructueuses
      // Vue.js semble retrouver les clés et les affiche, même lorsque l'objet à été reconstruit from scratch.
      // Ca semble absurde, mais au lieu de perdre du temps, on va simplement filter les clés à l'affichage dans LicenseDetail
      this.$emit('branchSelected', b);
    }
  }
}
</script>

<style scoped>
.smol-text {
  font-size: x-small;
}

.one-line {
  display: block;
  width: 6em;
}
</style>
