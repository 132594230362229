const InvoicingBusiness = {

	optionCodeToText(code) {
		switch (code) {
			case "LICENSE_PILOT": return "Licence Pilot";
			case "LICENSE_CURRENCY_PER_SEAT": return "Licence Devise (par poste)";
			case "LICENSE_METAL_PER_SEAT": return "Licence Métaux (par poste)";
			case "LICENSE_CURRENCY_AND_METAL_PER_SEAT": return "Licence Devise et Métaux (par poste)";
			case "ADMIN_READONLY_SEATS": return "Poste admin";
			case "CLOUD_PER_SEAT": return "Abonnement Cloud (par poste)";
			case "BACKUP": return "Sauvegarde externalisé";
			case "DETAX_PER_SEAT": return "Détaxe (par poste)";
			case "DOW_JONES_PER_SEAT": return "Dow Jones (par poste)";
			case "ADVANCED_USERS": return "Gestion avancée des utilisateurs";
			case "IDEN_TT_PER_SEAT_CUSTOM": return "IdenTT (par poste)";
			case "YODA_ID_ASSIST_PER_SEAT_CUSTOM": return "Yoda ID Assistant (par poste)";
			case "WEB_SYNC_SALES": return "Synchronisation Web-Yodaforex avec système de commande";
			case "WEB_SYNC_BOOKING": return "Synchronisation Web-Yodaforex pour affichage des cours";

			default: return code;
		}
	}

}

export default InvoicingBusiness;
