<template>
  <v-card>
    <v-snackbar color="error" v-model="error">Une erreur est survenue</v-snackbar>
    <v-card-title>Modifier l'agence
      <v-spacer/>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-form v-model="isFormValid">
      <v-card-subtitle>Nom de l'agence</v-card-subtitle>
      <v-card-text>
        <v-text-field outlined label="Nom de l'agence" hint="Utilisé pour reconnaitre l'agence dans le cloud"
                      v-model="form.name"
                      :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Nom de l'enseigne commerciale" hint="Apparaitra sur les tickets"
                      v-model="form.shopName"
                      :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Nom au sein de Yodatech"
                      hint="Sera utilisé en interne pour mieux différencier les agences"
                      v-model="form.yodatechName" :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Nom court à 3 caractères unique" v-model="form.shortName"
                      :rules="[rules.mandatory, rules.trigram]" counter="3"
                      hint="Un trigramme pour que les clients puissent facilement différencier leurs agences au sein d'un groupe, unique dans le cloud"/>
      </v-card-text>

      <v-card-subtitle>Adresse de l'agence</v-card-subtitle>
      <v-card-text>
        <v-text-field outlined label="Ville" v-model="form.address.city" :rules="[rules.mandatory]"
                      hint="La rue et numéro de rue de l'agence"/>
        <v-text-field outlined label="Code Postal" v-model="form.address.zipCode" :rules="[rules.mandatory]"
                      hint="Le code postal de l'agence"/>
        <v-text-field outlined label="Adresse" v-model="form.address.street" :rules="[rules.mandatory]"
                      hint="La rue et numéro de rue de l'agence"/>
        <v-combobox outlined label="Pays" v-model="form.address.country" :rules="[rules.mandatory]" :items="countries"
                    hint="Le pays d'activité de l'agence"/>
      </v-card-text>
    </v-form>
    <v-card-actions>
      <v-btn text @click="$emit('close')">Annuler</v-btn>
      <v-spacer/>
      <v-btn text :loading="loading" @click="submit" color="primary" :disabled="!isFormValid">Sauvegarder</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";

export default {
  name: "BranchEditCard",
  props: ['branch'],
  data: () => ({
    isFormValid: false,
    loading: false,
    error: false,
    form: {
      name: "",
      shopName: "",
      yodatechName: "",
      shortName: "",
      address: {
        city: "",
        zipCode: "",
        street: "",
        country: "",
      }
    },
    rules: {
      mandatory: v => !!v || 'Champ obligatoire',
      trigram: v => (v || '').length === 3 || '3 caractères sont requis'
    },
  }),
  mounted() {
    this.prepareForm(this.branch);
  },
  watch: {
    branch(b) {
      this.prepareForm(b);
    }
  },
  computed: {
    countries() {
      return LicenseBusiness.getKeysMap()["general.paysdactivite"].values;
    }
  },
  methods: {
    prepareForm(branch) {
      this.form.name = branch.name;
      this.form.shopName = branch.shopName;
      this.form.yodatechName = branch.yodatechName;
      this.form.shortName = branch.shortName;
      Object.assign(this.form.address, branch.address);
    },
    submit() {
      if (this.isFormValid) {
        this.error = false;
        this.loading = true;
        this.$http.put(this.$config.apiUrl + "/v1/branches/" + this.branch.uuid, this.form)
            .then(() => {
              this.$emit('should-update');
              this.$emit('close');
            })
            .catch(() => this.error = true)
            .finally(() => this.loading = false);
      }
    }
  }
}
</script>

<style scoped>

</style>
