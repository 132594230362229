<template>
  <v-card :loading="loading">
    <v-card-title>Rapport du<span class="ml-1" v-if="completeReport && !loading">{{completeReport.startedAt | moment('L')}}</span><v-skeleton-loader class="ml-2" width="300" type="heading" v-else/><v-spacer/><v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
    <v-tabs show-arrows center-active v-model="selectedTab">
      <v-tab>Anomalies ({{branchesWithInconsistencies.length}})</v-tab>
      <v-tab>Conformités ({{branchesWithoutInconsistencies.length}})</v-tab>
      <v-tab>Erreurs ({{branchesWithErrors.length}})</v-tab>
      <v-tab>Statistiques</v-tab>
      <v-tab>Recherche</v-tab>
    </v-tabs>
    <v-skeleton-loader v-if="loading" type="article@3"/>
    <div v-else-if="completeReport">

      <v-tabs-items v-model="selectedTab">
        <v-tab-item> <!-- TAB Anomalie -->
          <v-card-text class="d-flex flex-row">
            <y-branch-select class="mr-4" v-model="filterBranch" hide-details label="Filtrer sur une agence"/>
            <v-switch v-model="hideSolvedInconsistencies" label="Masquer les anomalies résolues"/>
          </v-card-text>
          <v-card-text v-if="branchesWithInconsistenciesWithSolvedFilter.length === 0">
            <v-alert type="success">Aucune anomalie à afficher</v-alert>
          </v-card-text>
          <v-card flat class="ma-4 mb-16" v-for="(rb,i) in branchesWithInconsistenciesWithSolvedFilter" :key="i+'with'">
            <v-card-title>
              <div>
                <y-branch-label :value="rb.branchUuid" show-tooltip-only-on-error>
                  <template v-slot:default="{branch}">
                    {{branch.companyName}} - {{branch.shopName}} <span class="caption">/ {{branch.name}}</span> <span class="text--disabled caption" v-if="branch.yodatechName">({{branch.yodatechName}})</span>
                  </template>
                </y-branch-label>
              </div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" v-bind="attrs" v-on="on" fab icon x-small><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                </template>
                <template v-slot:default>
                  <v-list dense>
                    <v-list-item v-if="rb.axonautOrganisationUrl" @click="openInNew(rb.axonautOrganisationUrl)">
                      <v-list-item-title><v-icon x-small class="mr-1">mdi-open-in-new</v-icon>Voir l'organisation Axonaut</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="rb.axonautMainContractUrl" @click="openInNew(rb.axonautMainContractUrl)">
                      <v-list-item-title><v-icon x-small class="mr-1">mdi-open-in-new</v-icon>Voir le contrat utilisé dans Axonaut</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="rb.axonautInvoiceUrl" @click="openInNew(rb.axonautInvoiceUrl)">
                      <v-list-item-title><v-icon x-small class="mr-1">mdi-open-in-new</v-icon>Voir la facture utilisée dans Axonaut</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openInNew('/#/branches/'+rb.branchUuid)">
                      <v-list-item-title><v-icon x-small class="mr-1">mdi-open-in-new</v-icon>Voir la licence</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </template>
              </v-menu>
            </v-card-title>
            <v-row v-for="(inconsistency, idx) in rb.inconsistencies" :key="idx" class="mt-4">
              <v-col v-if="!hideSolvedInconsistencies || !inconsistency.solved">
                <invoicing-inconsistency-report-line :inconsistency="inconsistency"/>
              </v-col>
            </v-row>
            <v-card-subtitle v-if="rb.inconsistencies.length === 0">Toutes les incohérences pour cette agence ont été résolues.</v-card-subtitle>
          </v-card>
        </v-tab-item>
        <v-tab-item> <!-- TAB Conformités -->
          <v-card-text>
          <ul>
            <li v-for="(rb,i) in branchesWithoutInconsistencies" :key="i+'without'">
              <y-branch-label :value="rb.branchUuid" show-tooltip-only-on-error>
                <template v-slot:default="{branch}">
                  {{branch.companyName}} - {{branch.shopName}} <span class="caption">/ {{branch.name}}</span> <span class="text--disabled caption" v-if="branch.yodatechName">({{branch.yodatechName}})</span>
                </template>
              </y-branch-label>
            </li>
          </ul>
          </v-card-text>
        </v-tab-item>
        <v-tab-item><!-- TAB Erreurs -->
            <v-simple-table>
              <thead>
                <tr>
                  <th>Agence</th>
                  <th>Erreur</th>
                  <th/>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rb,i) in branchesWithErrors" :key="i+'error'">
                  <td>
                    <y-branch-label :value="rb.branchUuid" show-tooltip-only-on-error :copy-on-click="false">
                      <template v-slot:default="{branch}">
                        {{branch.companyName}} - {{branch.shopName}} <span class="caption">/ {{branch.name}}</span> <span class="text--disabled caption" v-if="branch.yodatechName">({{branch.yodatechName}})</span>
                      </template>
                    </y-branch-label>
                  </td>
                  <td>
                    {{rb.error}}
                  </td>
                  <td>
                    <v-btn icon small @click="openInNew('/#/branches/'+rb.branchUuid)"><v-icon small>mdi-open-in-new</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
        </v-tab-item>
        <v-tab-item><!-- TAB Statistiques -->
          <v-card-text>
            {{allBranches.length}} agences analysées, {{allBranches.length - branchesWithErrors.length}} analyses réussies ({{branchesWithErrors.length}} agences en erreurs). {{branchesWithoutInconsistencies.length}} agences sans anomalies, {{branchesWithInconsistencies.length}} avec anomalies.
          </v-card-text>
          <v-simple-table>
            <thead>
            <tr>
              <th>Code Option</th>
              <th>Manquants</th>
              <th>En Trop</th>
              <th>Balance</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,option) of statistics" :key="option">
              <th>{{optionCodeToText(option)}}</th>
              <td>{{data.missing}}</td>
              <td>{{data.surplus}}</td>
              <td>{{data.balance}}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item><!-- TAB Recherche -->
          <v-card-text>
            <y-branch-select v-model="searchSelectedBranch" outlined/>
            <div v-if="searchSelectedBranch">
              <span v-if="!searchSelectedBranchReport || !searchSelectedBranchReport.inconsistencies">Aucun rapport trouvé pour cette agence</span>
              <v-alert class="mt-6" v-else-if="searchSelectedBranchReport.inconsistencies.length === 0" type="success">Aucune incohérence détectée pour cette agence.</v-alert>
              <div v-else>
                <v-row v-for="(inconsistency, idx) in searchSelectedBranchReport.inconsistencies" :key="idx" class="mt-4">
                  <v-col>
                    <invoicing-inconsistency-report-line :inconsistency="inconsistency"/>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>




    </div>
  </v-card>
</template>

<script>
import InvoicingBusiness from "@/business/invoicing-business";
import InvoicingInconsistencyReportLine from "@/components/InvoicingInconsistencyReportLine";

export default {
  name: "InvoicingInconsistencyReportCard",
  components: {InvoicingInconsistencyReportLine},
  props: ['report'],
  data: ()=>({
    selectedTab: 0,
    completeReport: null,
    loading: false,
    error: false,
    searchSelectedBranch: null,
    hideSolvedInconsistencies: false,
    filterBranch: null,
  }),
  watch: {
    report() {
      this.fetch();
    }
  },
  computed: {
    todoReport() {
      return {
        axonautOrganisationUrl: null,
        axonautMainContractUrl: null,
        inconsistencies: this.report.inconsistencies
      }
    },
    branchesWithInconsistenciesWithSolvedFilter() {
      return this.completeReport ? this.completeReport.reportBranches.filter((b) => {

        if (this.filterBranch && b.branchUuid !== this.filterBranch.uuid) return false;

        if (b.error) return false; // Filtre directement les branch en erreur

        // Si demandé, on filtre les reportBranches qui ont toutes leurs anomalies de résolues
        if (this.hideSolvedInconsistencies) {
          for (const inconsistency of b.inconsistencies) {
            if (!inconsistency.solved) return true;
          }
          return false;
        } else {
          // Si le filtre sur les résolutions n'est pas activé, on retourne toutes les branches qui ont au moins 1 "inconsistencies"
          return b.inconsistencies.length > 0;
        }
      }) : [];
    },
    branchesWithInconsistencies() {
      return this.completeReport ? this.completeReport.reportBranches.filter((b) => b.inconsistencies.length > 0 && !b.error && (!this.filterBranch || b.branchUuid === this.filterBranch.uuid)) : [];
    },
    allBranches() {
      return this.completeReport ? this.completeReport.reportBranches : [];
    },
    branchesWithoutInconsistencies() {
      return this.completeReport ? this.completeReport.reportBranches.filter((b) => b.inconsistencies.length === 0 && !b.error) : [];
    },
    branchesWithErrors() {
      return this.completeReport ? this.completeReport.reportBranches.filter((b) => !!b.error) : [];
    },
    searchSelectedBranchReport() {
      let matching = this.searchSelectedBranch ? this.completeReport.reportBranches.filter((b) => b.branchUuid === this.searchSelectedBranch.uuid) : [];
      return matching.length === 0 ? null : matching[matching.length-1]; // Si pour une raison inconnue il y a plusieurs rapports, on prend le dernier (y'a un moment y'avait un bug et c'était le dernier qui était le bon, so be it)
    },
    statistics() {
      if (!this.completeReport) return null;
      let result = {};
      for (const rb of this.completeReport.reportBranches) {
        for (const ri of rb.inconsistencies) {
          if (!result[ri.option]) {
            result[ri.option] = {missing: 0, surplus: 0, balance: 0};
          }
          if (ri.quantityDelta < 0) {
            result[ri.option].missing+=(-1*ri.quantityDelta)
          }
          else {
            result[ri.option].surplus+=ri.quantityDelta
          }
          result[ri.option].balance+=ri.quantityDelta
        }
      }
      return result;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.error = false;
      this.loading = true;
      this.$http.get(this.$config.apiUrl + "/v1/invoicing/inconsistency-reports/"+this.report.id)
          .then(r => this.completeReport = r.data)
          .catch(() => this.error = true)
          .finally(() => this.loading = false)

    },
    openInNew(target) {
      window.open(target, '_blank');
    },
    optionCodeToText(code) {
      return InvoicingBusiness.optionCodeToText(code)
    }
  }
}
</script>

<style scoped>

</style>
