<template>
  <v-card :loading="loadingCloudSetting || sending">
    <v-snackbar v-model="error" color="error" :timeout="3000" top>Une erreur est survenue</v-snackbar>

    <krypto-license-update-button/>

    <v-form>
      <v-card-title>
        Sélectionner une société existante dans Axonaut
      </v-card-title>
      <v-card-text>
        <axonaut-companies-select v-model="selectedAxonautCompany"/>
      </v-card-text>
    </v-form>
      <v-card-subtitle class="d-flex align-center">
        <span class="mr-2">Ou renseigner manuellement l'identifiant Axonaut :</span>
        <v-text-field outlined label="Identifiant de société Axonaut (obligatoire)" dense hide-details v-model="conf['support.axonaut.orgid']"/>
      </v-card-subtitle>

    <v-form :disabled="!conf['support.axonaut.orgid']" v-model="formValid" ref="form" lazy-validation>

      <v-card-title>Informations générales</v-card-title>
      <v-card-text>
        <v-text-field label="Nom de l'enseigne commerciale" hint="Apparaitra sur les tickets" v-model="branch.shopName"
                      :rules="[rules.mandatory]"/>
        <v-text-field label="Nom de l'agence" hint="Apparait sur l'écran de démarrage et dans la liste déroulante des agences du cloud" v-model="conf['cloud.agence.nom']"
                      :rules="[rules.mandatory]"/>
        <v-text-field label="Nom au sein de Yodatech" hint="Sera utilisé en interne pour mieux différencier les agences"
                      v-model="branch.yodatechName" :rules="[rules.mandatory]"/>
        <v-text-field label="Nom court à 3 caractères" v-model="conf['cloud.agence.trigramme']"
                      :rules="[rules.mandatory, rules.trigram]" counter="3"
                      hint="Un trigramme pour que les clients puissent facilement différencier leurs agences au sein d'un groupe"/>
        <y-company-select label="Appartient à une société existante"
                          hint="Laisser vide si la société à laquelle l'agence appartient est nouvelle"
                          :hide-details="false" v-model="existingCompanySelection"/>
        <v-text-field label="Appartient à une nouvelle société"
                      hint="Ce nom sera utilisé pour toutes les prochaines agences. Le plus souvent, cette nouvelle société portera le nom de l'enseigne de l'agence."
                      v-model="branch.companyName" :rules="[dynamicRuleCompanyNameIfNewCompany]"/>
        <v-select :items="licenseTypeSelectItems" label="Type de licence" hint="Nature de l'activité de l'agence" v-model="conf['licence.type']"/>
        <v-select :items="keys['general.devisepivot.iso'].values" label="Code ISO de la devise pivot"
                  v-model="conf['general.devisepivot.iso']"/>
        <v-select :items="keys['general.devisepivot.base'].values" label="Base de la devise pivot"
                  hint="Attention, cette valeur n'est pas vérifiée, elle peut-être incohérente avec le code ISO sélectionné !"
                  v-model="conf['general.devisepivot.base']"/>
        <v-text-field v-model="conf['general.devisepivot.coupuremini']" label="Coupure minimum pour l'agence"
                      hint="Attention, cette valeur n'est pas vérifiée, elle peut-être incohérente avec le code ISO sélectionné !"/>
      </v-card-text>

      <v-card-title>Contact</v-card-title>
      <v-card-text>
        <v-autocomplete :items="keys['general.paysdactivite'].values" label="Pays"
                        v-model="conf['general.paysdactivite']" :rules="[rules.mandatory]"/>
        <v-text-field :rules="[rules.mandatory]" label="Adresse / Rue" v-model="branch.address.street"/>
        <v-text-field :rules="[rules.mandatory]" label="Ville" v-model="branch.address.city"/>
        <v-text-field :rules="[rules.mandatory]" label="Code Postal" v-model="branch.address.zipCode"/>
        <v-text-field :rules="[rules.mandatory, rules.email]" label="Email du responsable d'agence" v-model="conf['general.emailowner']"
                      hint="Sera notamment utilisé pour envoyer des alertes concernant l'état de l'agence"/>
      </v-card-text>

      <v-card-title>Cloud</v-card-title>
      <v-card-text>
        <v-switch false-value="false" true-value="true" label="Activer le cloud" v-model="conf['cloud.actif']"/>
        <div v-if="conf['cloud.actif'] === 'true'">
          <div v-if="existingCompanySelection">
            <v-btn outlined :loading="loadingCloudSetting" @click="loadCloudSettings">Remplissage automatique</v-btn>
          </div>
          <v-switch false-value="false" true-value="true" label="L'agence est visible des autres agences"
                    v-model="conf['cloud.agence.visible']"/>
          <v-text-field :rules="[rules.mandatoryForCloud]" v-model="conf['cloud.tempsattenteentresynchro']"
                        label="Temps d'attente entre les synchro. en secondes"
                        hint="Habituellement à 300 secondes (5 minutes), on le diminue seulement si d'autres agences sont très proches"/>
          <v-text-field :rules="[rules.mandatoryForCloud]" v-model="conf['cloud.wakanda.url']" label="URL Wakanda"/>
          <v-text-field :rules="[rules.mandatoryForCloud]" v-model="conf['cloud.wakanda.login']" label="Login Wakanda"/>
          <v-text-field :rules="[rules.mandatoryForCloud]" v-model="conf['cloud.wakanda.password']" label="Mot de passe Wakanda"/>
          <v-text-field :rules="[rules.mandatoryForCloud]" v-model="conf['cloud.manageapi.url']" label="URL de l'API de gestion du modèle de données"/>
          <v-text-field :rules="[rules.mandatoryForCloud]" v-model="conf['cloud.manageapi.key']"
                        label="Clé d'acccès à l'API de gestion du modèle de données"/>
          <v-text-field v-model="conf['cloud.updown.id']" label="Identifiant UpDown.io"/>
        </div>
      </v-card-text>


      <v-list>
        <v-list-group class="pa-0">
          <template v-slot:activator>
            <v-list-item inactive class="pa-0">
              <v-list-item-avatar>
                <v-icon>settings</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Configuration brute</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-for="(v,k,i) of conf" :key="i">
            <v-col>
              <license-conf-key-input :show-label="true" v-model="conf[k]" :conf-key="k"/>
            </v-col>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-card-actions>
        <v-btn text color="secondary" @click="$emit('cancel')">Annuler</v-btn>
        <v-spacer/>
        <v-btn text color="primary" @click="submit" :loading="sending">Créer la licence</v-btn>
      </v-card-actions>

    </v-form>
  </v-card>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";
import LicenseConfKeyInput from "@/components/LicenseConfKeyInput";
import AxonautCompaniesSelect from "@/components/AxonautCompaniesSelect";
import KryptoLicenseUpdateButton from "@/components/KryptoLicenseUpdateButton.vue";

export default {
  name: "LicenseCreation",
  components: {KryptoLicenseUpdateButton, AxonautCompaniesSelect, LicenseConfKeyInput},
  data: () => ({

    selectedAxonautCompany: null,

    rules: {
      mandatory: v => !!v || 'Champ obligatoire',
      mandatoryForCloud: v => !!v || 'Champ obligatoire pour activer le cloud',
      trigram: v => (v || '').length === 3 || '3 caractères sont requis',
      email: v => /.+@.+\..+/.test(v) || 'Cet email est invalide'
    },

    branch: {
      name: "",
      shopName: "",
      shortName: "",
      yodatechName: "",
      companyUuid: "",
      companyName: "",
      address: {
        street: "",
        city: "",
        zipCode: ""
      }
    },

    formValid: false,

    existingCompanySelection: null,

    loadingCloudSetting: false,
    error: false,
    sending: false,

    conf: LicenseBusiness.createEmptyConfiguration(),
    keys: LicenseBusiness.getKeysMap(),
    licenseTypeSelectItems: [
      {text: 'Devises seulement', value: 'CURRENCY'},
      {text: 'Métaux seulement', value: 'METAL'},
      {text: 'Devises et métaux', value: 'CURRENCY_AND_METAL'},
      {text: 'Pilote, consultation seulement', value: 'PILOT'},
      {text: 'Pro - Devises seulement', value: 'PRO_CURRENCY'},
      {text: 'Pro - Métaux seulement', value: 'PRO_METAL'},
      {text: 'Pro - Devises et métaux', value: 'PRO_CURRENCY_AND_METAL'},
      {text: 'Pro - Pilote, consultation seulement', value: 'PRO_PILOT'},
      {text: 'Compliance', value: 'COMPLIANCE'},
      {text: 'Compliance Light', value: 'COMPLIANCE_LIGHT'},
    ]
  }),
  watch: {
    existingCompanySelection(newVal) {
      if (newVal) {
        this.branch.companyName = null;
        this.branch.companyUuid = newVal.uuid;
      }
    },
    'branch.companyName'(newVal) {
      if (newVal) {
        this.existingCompanySelection = null;
      }
    },
    selectedAxonautCompany(v) {
      this.conf['cloud.agence.nom'] = v.name.toUpperCase();
      this.branch.shopName = v.name;
      this.branch.yodatechName = v.name;
      this.branch.address.zipCode = v.addressZipCode;
      this.branch.address.street = v.addressStreet;
      this.branch.address.city = v.addressCity;
      this.conf['support.axonaut.orgid'] = v.id;
      if (v.addressCountry.toUpperCase() !== "FRANCE") {
        this.conf['general.paysdactivite'] = null;
        this.conf['general.devisepivot.base'] = null;
        this.conf['general.devisepivot.iso'] = null;
        this.conf['general.devisepivot.coupuremini'] = null;
      }
    }
  },
  computed: {
    mandatoryKeys() {
      let keysArray = Object.keys(this.keys);
      let result = [];
      for (let k of keysArray) {
        if (this.keys[k].mandatoryAtCreation === true) {
          result.push(k);
        }
      }
      return result;
    },
    dynamicRuleCompanyNameIfNewCompany() {
      return (!!this.existingCompanySelection || !!this.branch.companyName) || 'Rattacher l\'agence à une société est obligatoire';
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.formValid && !this.sending) {
        this.sending = true;
        this.error = false;
        let payload = {};
        payload.license = {conf: {}, supportStatus: (this.conf['licence.assistance.actif'] ? 'ALLOWED' : 'NONE'), activationStatus: "ACTIVE", licenseType: this.conf['licence.type']};
        Object.assign(payload.license.conf, this.conf);
        Object.assign(payload, this.branch);
        payload.name = this.conf['cloud.agence.nom']
        payload.shortName = this.conf['cloud.agence.trigramme']
        payload.address.country = this.conf['general.paysdactivite']
        this.$http.post(this.$config.apiUrl + "/v1/branches", payload)
            .then((r) => {
              this.$emit('created', r.data);
            })
            .catch(() => this.error = true).finally(() => this.sending = false);
      }
    },
    loadCloudSettings() {
      if (this.existingCompanySelection && !this.loadingCloudSetting) {
        this.error = false;
        this.loadingCloudSetting = true;
        this.$http.get(this.$config.apiUrl + "/v1/companies/" + this.existingCompanySelection.uuid)
            .then(r => {
              this.loadLicences(r.data.branches.map(b => b.uuid))
                  .then(sistersLicense => this.extract(sistersLicense))
                  .finally(() => this.loadingCloudSetting = false)
            }).catch(() => this.error = true)
      }
    },
    loadLicences(uuids) {
      let requests = uuids.map(uuid => this.$http.get(`${this.$config.apiUrl}/v1/branches/${uuid}/license`));
      return new Promise((resolve, reject) => {
        this.loadSingleLicence(requests, [], 0, resolve, reject);
      });
    },
    loadSingleLicence(requests, resultSet, currentIndex, resolve, reject) {
      requests[currentIndex].then(r => resultSet.push(r.data))
          .catch((e) => reject(e))
          .finally(() => {
            if (currentIndex === requests.length - 1) resolve(resultSet);
            else this.loadSingleLicence(requests, resultSet, ++currentIndex, resolve, reject);
          })
    },
    extract(sistersLicense) {
      const cloudConfigurationsJson = [];
      for (const sister of sistersLicense) {
        if (sister.conf['cloud.actif'] === 'true') {
          let conf = {};
          conf['cloud.manageapi.key'] = sister.conf['cloud.manageapi.key'];
          conf['cloud.manageapi.url'] = sister.conf['cloud.manageapi.url'];
          conf['cloud.tempsattenteentresynchro'] = sister.conf['cloud.tempsattenteentresynchro'];
          conf['cloud.updown.id'] = sister.conf['cloud.updown.id'];
          conf['cloud.wakanda.login'] = sister.conf['cloud.wakanda.login'];
          conf['cloud.wakanda.password'] = sister.conf['cloud.wakanda.password'];
          conf['cloud.wakanda.url'] = sister.conf['cloud.wakanda.url'];

          let asJson = JSON.stringify(conf);
          if (cloudConfigurationsJson.indexOf(asJson) < 0) cloudConfigurationsJson.push(asJson);
        }
      }
      if (cloudConfigurationsJson.length > 1) {
        // XXX proposer le choix. En attendant on fait un truc très bête : on prend la config qui a le JSON le plus long
        let longestJson = "";
        for (const json of cloudConfigurationsJson) {
          if (json.length > longestJson.length) longestJson = json;
        }
        Object.assign(this.conf, JSON.parse(longestJson));
      } else {
        alert('Aucune configuration disponible')
      }
    }
  }
}
</script>

<style scoped>

</style>
