var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"items":_vm.branchesWithUsableConf,"headers":_vm.dynamicHeaders,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.branchClicked},scopedSlots:_vm._u([{key:"item.shopName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shopName)),(item.name)?_c('span',[_vm._v(" ("+_vm._s(item.name)+")")]):_vm._e(),(item.yodatechName)?_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(item.yodatechName))]):_vm._e()]),_c('br'),_c('pre',{staticClass:"grey--text smol-text"},[_vm._v(_vm._s(item.uuid))])]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.companyName))]),_c('br'),_c('pre',{staticClass:"grey--text smol-text"},[_vm._v(_vm._s(item.companyUuid))])]}},{key:"item.licenseSupportStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"smol-text one-line"},[_vm._v(" Support "),_c('v-icon',{attrs:{"small":"","color":_vm._f("colorForStatus")(item.licenseSupportStatus),"aria-hidden":"false"}},[_vm._v(" "+_vm._s(_vm._f("iconForStatus")(item.licenseSupportStatus))+" ")])],1),_c('div',{staticClass:"smol-text one-line"},[_vm._v(" Licence "),_c('v-icon',{attrs:{"small":"","color":_vm._f("colorForStatus")(item.licenseActivationStatus),"aria-hidden":"false"}},[_vm._v(" "+_vm._s(_vm._f("iconForStatus")(item.licenseActivationStatus))+" ")])],1)]}},{key:"item.license.conf.cloud_actif",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.license.conf['cloud.actif'] === 'true' ? 'light-blue' : 'grey lighten-1'}},[_vm._v("mdi-cloud")])]}},{key:"item.license.conf.ea_actif",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.license.conf['ea.actif'] === 'true' ? 'amber' : 'grey lighten-1'}},[_vm._v("mdi-star")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }