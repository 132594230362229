<template>
  <v-container>

    <v-dialog v-model="showDetail" max-width="800">
      <invoicing-inconsistency-report-card @close="showDetail = false" :report="selectedReport"/>
    </v-dialog>


    <v-card :loading="loadingList">
      <v-card-title>Rapports de cohérence</v-card-title>
      <v-card-subtitle>Vérification de la cohérence entre les factures réalisées sur Axonaut et les configurations des
        licences Yodaforex
      </v-card-subtitle>

      <v-card-text class="mb-4">
        <v-btn v-if="!currentReportProgress" outlined rounded block @click="computeReport" :loading="loadingComputeRequest">
          Démarrer un nouveau calcul
        </v-btn>
        <div v-else>
          <v-progress-linear striped active rounded height="10" class="mb-3"
              :value="currentReportProgressPercentage" stream :buffer-value="currentReportProgressPercentage"/>
          <v-btn color="warning" rounded block @click="cancelReport" :loading="loadingComputeRequest">Stopper le calcul en cours</v-btn>
        </div>
      </v-card-text>

      <div v-if="lastSuccessfulReport && !loadingList">
        <v-card-subtitle>Dernier rapport réussi</v-card-subtitle>
        <v-list-item-group>
          <v-list-item @click="reportClicked(lastSuccessfulReport)">
            <v-list-item-avatar>
              <v-icon v-if="lastSuccessfulReport.status === 'ENDED_WITH_ERRORS'" color="warning">check_circle</v-icon>
              <v-icon v-else-if="lastSuccessfulReport.status === 'ENDED_SUCCESSFULLY'" color="success">check_circle</v-icon>
              <v-icon v-else color="gray">help</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Commencé {{ lastSuccessfulReport.startedAt | moment('LLLL') }}</v-list-item-title>
              <v-list-item-subtitle v-if="lastSuccessfulReport.endedAt">
                Terminé {{ lastSuccessfulReport.endedAt | moment('LLLL') }}
                <span v-if="lastSuccessfulReport.errorType">&nbsp;—&nbsp;{{ lastSuccessfulReport.errorType }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
      <v-card-subtitle v-if="!lastSuccessfulReport && !loadingList">Aucun rapport réussi à afficher</v-card-subtitle>

      <v-skeleton-loader v-if="loadingList" type="list-item-two-line@5"/>

      <v-expansion-panels v-else>
        <v-expansion-panel class="">
          <v-expansion-panel-header>
            Voir tous les rapports
          </v-expansion-panel-header>
          <v-expansion-panel-content class="no-gutters-on-expansion-panel">
            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(r,i) in reports" :key="i" @click="reportClicked(r)">
                  <v-list-item-avatar>
                    <v-progress-circular v-if="r.status === 'IN_PROGRESS'" indeterminate size="16"/>
                    <v-icon v-else-if="r.status === 'CANCELLED'" color="warning">pan_tool</v-icon>
                    <v-icon v-else-if="r.status === 'ENDED_WITH_ERRORS'" color="warning">check_circle</v-icon>
                    <v-icon v-else-if="r.status === 'PREMATURELY_ENDED_WITH_ERRORS'" color="error">cancel</v-icon>
                    <v-icon v-else-if="r.status === 'ENDED_SUCCESSFULLY'" color="success">check_circle</v-icon>
                    <v-icon v-else color="gray">help</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Commencé {{ r.startedAt | moment('LLLL') }}</v-list-item-title>
                    <v-list-item-subtitle v-if="r.endedAt">
                      Terminé {{ r.endedAt | moment('LLLL') }}
                      <span v-if="r.errorType">&nbsp;—&nbsp;{{ r.errorType }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


    </v-card>
  </v-container>
</template>

<script>
import InvoicingInconsistencyReportCard from "@/components/InvoicingInconsistencyReportCard";

export default {
  name: "InvoicingView",
  components: {InvoicingInconsistencyReportCard},
  data: () => ({
    reports: [],
    loadingList: false,
    loadingComputeRequest: false,
    error: false,
    showDetail: false,
    selectedReport: null,
    currentReportProgress: null
  }),
  mounted() {
    this.loadingList = true;
    this.fetch();
  },
  timers: {
    updateForCurrentProcessing: {autostart: true, time: 3000, repeat: true, immediate: true, isSwitchTab: true},
    fetch: {autostart: true, time: 3000, repeat: true, immediate: true, isSwitchTab: true}
  },
  computed: {
    lastSuccessfulReport() {
      if (!this.reports || this.reports.length === 0) return null;
      let successfulReports = this.reports.filter(r => r.status === 'ENDED_SUCCESSFULLY' || r.status === 'ENDED_WITH_ERRORS');
      return successfulReports.length === 0 ? null : successfulReports[0];
    },
    currentReportProgressPercentage() {
      if (!this.currentReportProgress) return 0;
      return Math.round(this.currentReportProgress.branchProcessedCount / this.currentReportProgress.branchTotalCount  * 100);
    }
  },
  watch: {
    showDetail(v) {
      if (v) {
        this.$timer.stop('updateForCurrentProcessing');
        this.$timer.stop('fetch');
      } else {
        this.$timer.start('updateForCurrentProcessing');
        this.$timer.start('fetch');
      }
    }
  },
  methods: {
    fetch() {
      this.error = false;
      this.$http.get(this.$config.apiUrl + "/v1/invoicing/inconsistency-reports")
          .then(r => this.reports = r.data)
          .catch(() => this.error = true)
          .finally(() => this.loadingList = false)
    },
    reportClicked(r) {
      this.showDetail = true;
      this.selectedReport = r;
    },
    computeReport() {
      this.loadingComputeRequest = true;
      this.$http.post(this.$config.apiUrl + "/v1/invoicing/inconsistency-reports/compute")
          .then(() => {
            this.fetch();
            this.currentReportProgress = {branchProcessedCount: 0, branchTotalCount: 1}
          })
          .catch(() => this.error = true)
          .finally(() => this.loadingComputeRequest = false)
    },
    updateForCurrentProcessing() {
      this.$http.get(this.$config.apiUrl + "/v1/invoicing/inconsistency-reports/current/progression")
          .then((r) => this.currentReportProgress = r.data)
          .catch(() => this.currentReportProgress = null);
    },
    cancelReport() {
      this.loadingComputeRequest = true;
      this.$http.put(this.$config.apiUrl + "/v1/invoicing/inconsistency-reports/current/cancel")
          .then(() => this.currentReportProgress = null)
          .finally(() => this.loadingComputeRequest = false);
    }
  }
}
</script>

<style>
.no-gutters-on-expansion-panel > div {
  padding: 0;
}
</style>
