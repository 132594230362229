<template>
  <v-container>
  <v-card>
    <v-card-text>
      <v-treeview :items="tree" dense open-all open-on-click>
        <template v-slot:label="{item}">
          <span>{{ item.version ? item.version : "Répartition actuelle des versions" }}</span>
          <v-chip small class="ma-2">{{ item.count }}</v-chip>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
export default {
  name: "VersionView",
  data: () => ({
    tree: []
  }),
  mounted() {
    this.$http.get(this.$config.apiUrl + "/v1/versions?asTree").then(r => this.tree = [r.data]);
  },
  methods: {
    goTo(item) {
      console.log(item)
    }
  }
}
</script>

<style scoped>

</style>
