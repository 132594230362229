<template>
  <v-card rounded elevation="6" color="warning lighten-3">
    <div class="ma-1" style="position:absolute; right: 0;" v-if="inconsistency.id">
      <v-tooltip left>
        <template v-slot:activator="{on,attrs}">
          <v-btn v-if="inconsistency.solved" :loading="statusLoading" v-bind="attrs" v-on="on" x-small fab icon color="success" @click="toggleStatus"><v-icon>mdi-check-circle</v-icon></v-btn>
          <v-btn v-else :loading="statusLoading" v-bind="attrs" v-on="on" x-small fab icon color="warning" @click="toggleStatus"><v-icon>mdi-circle-outline</v-icon></v-btn>
        </template>
        <template v-slot:default>
          <span v-if="inconsistency.solved">Annuler la résolution</span>
          <span v-else>Marquer cette anomalie comme résolue</span>
        </template>
      </v-tooltip>
    </div>
    <v-card-text class="pa-1">
      <v-container>
        <v-row>
          <v-col>L'option
            <v-tooltip bottom>
              Codes produit correspondant :
              <ul>
                <li v-for="(opt,i) in inconsistency.optionProductCodes" :key="i">
                  <span class="font-weight-bold">{{ opt }}</span>
                  <span v-if="i < inconsistency.optionProductCodes.length - 1">, </span>
                </li>
              </ul>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="font-weight-bold text-decoration-underline">{{ optionCodeToText(inconsistency.option) }}</span>
              </template>
            </v-tooltip>

            <span v-if="inconsistency.type === 'WRONG_QUANTITY_BILLED'"> a une incohérence.</span>
            <span v-else-if="inconsistency.type === 'SHOULD_NOT_BE_BILLED'"> est facturé, mais n'est pas actif sur la licence.</span>
            <span v-else-if="inconsistency.type === 'SHOULD_BE_BILLED'"> n'est pas facturé, alors qu'elle est active sur la licence.</span>
          </v-col>
        </v-row>
<!--        <v-row>-->
<!--          <v-col>-->
<!--            <span v-if="inconsistency.quantityDelta > 0">Cet élément est facturé <span class="font-weight-bold">{{inconsistency.quantityDelta }}</span> fois de trop.</span>-->
<!--            <span v-else>Cet élément devrait être facturé <span class="font-weight-bold">{{-1 * inconsistency.quantityDelta }}</span> fois de plus.</span>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row>
          <v-col>
            <span v-if="inconsistency.type === 'SHOULD_NOT_BE_BILLED'">Cet élément est facturé <span class="font-weight-bold">{{inconsistency.billedQuantity }}</span> fois dans Axonaut. Vérifiez où est la source de l'erreur.</span>
            <span v-else-if="inconsistency.type === 'SHOULD_BE_BILLED'">Cet élément n'est <span class="font-weight-bold">pas facturé</span> dans Axonaut, mais devrait probablement l'être <span class="font-weight-bold">{{-1*inconsistency.quantityDelta}}</span> fois. Vérifiez où est la source de l'erreur.</span>
            <span v-else>Cet élément est facturé <span class="font-weight-bold">{{inconsistency.billedQuantity }}</span> fois dans Axonaut, mais la licence dit qu'il devrait être facturé <span class="font-weight-bold">{{inconsistency.billedQuantity - inconsistency.quantityDelta}}</span> fois. Vérifiez où est la source de l'erreur.</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Car les valeurs suivantes ont été relevées dans la configuration de la licence : </span>
            <ul>
              <li v-for="(conf,key) in inconsistency.correspondingLicenseConf" :key="key">{{ key }} → {{ conf }}</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InvoicingBusiness from "@/business/invoicing-business";

export default {
  name: "InvoicingInconsistencyReportLine",
  props: ["inconsistency"],
  data: ()=>({
    statusLoading: false,
    statusError: false
  }),
  methods: {
    toggleStatus() {
      this.statusError = false;
      this.statusLoading = true;
      this.$http.put(this.$config.apiUrl+`/v1/invoicing/inconsistency-reports-lines/${this.inconsistency.id}`, {solved: !this.inconsistency.solved})
          .then(()=>this.inconsistency.solved = !this.inconsistency.solved)
          .catch(()=>this.statusError=true)
          .finally(()=>this.statusLoading = false)
    },
    optionCodeToText(code) {
      return InvoicingBusiness.optionCodeToText(code)
    }
  }
}
</script>

<style scoped>

</style>

