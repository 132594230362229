<template>
  <v-row>
    <v-col cols="12" sm="3">
      <div class="d-flex align-center">
        <v-text-field placeholder="75001" v-model="zipCode" label="Code postal" ref="zipCodeField"
                      :rules="[rules.mandatory]"/>
        <v-btn small icon elevation="1" @click="fetchSuggestions(zipCode)">
          <v-icon color="primary">search</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="9">
      <v-autocomplete :disabled="selected === null" v-model="selected" placeholder="Sélectionner une agence" :items="suggestions" item-text="name"
                      :loading="loading" return-object/>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AxonautCompaniesSelect",
  props: ['value'],
  data: () => ({
    rules: {mandatory: v => !!v || 'Champ obligatoire'},
    error: false,
    loading: false,
    zipCode: null,
    suggestions: [],
    selected: null
  }),
  watch: {
    value(v) {
      this.selected = v;
    },
    selected(v) {
      this.$emit('input', v);
    }
  },
  methods: {
    fetchSuggestions(zipCode) {
      this.error = false;
      this.loading = true;
      this.$http.get(this.$config.apiUrl + '/v1/license-creation/company-suggestions', {params: {zipCode: zipCode}})
          .then((r) => {
            this.suggestions = r.data;
            if (this.suggestions.length > 0) {
              this.selected = this.suggestions[0];
            }
          })
          .catch(() => this.error = true)
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
