<template>
  <div>
    <v-autocomplete outlined multiple deletable-chips chips clearable v-model="selected" :items="keys"
              label="Rechercher parmi les clés..." prepend-inner-icon="search"/>
  </div>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";

export default {
  name: "LicenseFieldsSelect",
  props: ['value'],
  data: () => ({
    showKeys: false,
    selected: [],
    keys: [],
  }),
  created() {
    this.keys = LicenseBusiness.getKeysForSelect();
    if (this.value) {
      this.selected = this.value;
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal;
    }
    ,
    selected(newVal) {
      this.$emit('input', newVal);
    }
  }
}
</script>

<style scoped>

</style>
