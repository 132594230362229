<template>
  <div>

    <v-list class="overflow-auto">
      <!-- XXX Verrue temporaire pour la colonne Catalina -->
      <v-list-item v-if="isUserSupport">
        <v-list-item-title>Catalina</v-list-item-title>
        <v-list-item-title>
          <div class="d-flex align-center">
            <v-btn-toggle v-model="catalinaBtnGroup" :disabled="catalinaIsLoading">
              <v-btn outlined :loading="catalinaIsLoading">Oui</v-btn>
              <v-btn outlined :loading="catalinaIsLoading">Non</v-btn>
            </v-btn-toggle>
          </div>
        </v-list-item-title>
      </v-list-item>
      <!--EA-->
      <v-list-item>
        <v-list-item-title>Early Access</v-list-item-title>
        <v-list-item-title>
          <div class="d-flex align-center">
            <v-icon class="mr-1" :color="selectedBranchIsEa ? 'amber' : 'grey lighten-1'">mdi-star</v-icon>
            <span>{{ selectedBranchIsEa ? 'Oui' : 'Non' }}</span>

            <v-btn v-if="confKeyWithHistory.indexOf('ea.actif')>=0" @click="showHistory('CONF', 'ea.actif')" icon x-small class="ml-2" :loading="loadingHistory"><v-icon>mdi-history</v-icon></v-btn>
            <v-btn icon x-small class="ml-2" v-else-if="!loadingHistory && errorLoadingHistory" @click="showHistory"><v-icon>mdi-alert-circle-outline</v-icon></v-btn>

            <v-spacer/>

            <v-btn v-if="isUserSupport" outlined :loading="eaIsLoading" @click="toggleEa">
              {{ (selectedBranchIsEa ? 'Désactiver' : 'Activer') }} le Early Access
            </v-btn>
          </div>
        </v-list-item-title>
      </v-list-item>
      <!--Status-->
      <v-list-item>
        <v-list-item-title>Status de la licence</v-list-item-title>
        <v-list-item-title>
          <div class="d-flex align-center">
            <v-icon :color="selectedBranch.licenseActivationStatus | colorForStatus" aria-hidden="false">
              {{ selectedBranch.licenseActivationStatus | iconForStatus }}
            </v-icon>
            <span class="ml-1">{{ selectedBranch.licenseActivationStatus }}</span>

            <v-btn v-if="logTypesWithHistory.indexOf('ACTIVATION_STATUS')>=0" @click="showHistory('ACTIVATION_STATUS')" icon x-small class="ml-2" :loading="loadingHistory"><v-icon>mdi-history</v-icon></v-btn>
            <v-btn icon x-small class="ml-2" v-else-if="!loadingHistory && errorLoadingHistory" @click="showHistory"><v-icon>mdi-alert-circle-outline</v-icon></v-btn>

            <v-spacer/>

            <v-btn v-if="isUserSupport && canBeDeactivated" outlined color="error" @click="showDeactivationDialog=true">Désactiver la licence</v-btn>
            <v-btn v-else-if="isUserSupport && canBeActivated" outlined color="warning" :loading="activationLoading" @click="activateLicense">Activer la licence</v-btn>
          </div>
        </v-list-item-title>
      </v-list-item>
      <!--Support-->
      <v-list-item>
        <v-list-item-title>Status du support</v-list-item-title>
        <v-list-item-title>
          <v-icon :color="selectedBranch.licenseSupportStatus | colorForStatus" aria-hidden="false">
            {{ selectedBranch.licenseSupportStatus | iconForStatus }}
          </v-icon>
          {{ selectedBranch.licenseSupportStatus }}
        </v-list-item-title>
      </v-list-item>
      <!--Type-->
      <v-list-item>
        <v-list-item-title>Type</v-list-item-title>
        <v-list-item-title>{{ selectedBranch.license.licenseType }}</v-list-item-title>
      </v-list-item>
      <!--Clé-->
      <v-list-item>
        <v-list-item-title>Clé</v-list-item-title>
        <v-list-item-title>
          <pre>{{ selectedBranch.license.licenseKey }}</pre>
        </v-list-item-title>
      </v-list-item>
      <!--Version actuelle-->
      <v-list-item>
        <v-list-item-title>Version actuelle</v-list-item-title>
        <v-list-item-title class="d-flex">
          {{ selectedBranch.yodaforexVersion }}
          <v-btn v-if="logTypesWithHistory.indexOf('VERSION')>=0" @click="showHistory('VERSION')" icon x-small class="ml-2" :loading="loadingHistory"><v-icon>mdi-history</v-icon></v-btn>
          <v-btn icon x-small class="ml-2" v-else-if="!loadingHistory && errorLoadingHistory" @click="showHistory"><v-icon>mdi-alert-circle-outline</v-icon></v-btn>
        </v-list-item-title>
      </v-list-item>
      <!--Version max-->
      <v-list-item v-if="selectedBranch.licenseActivationStatus === 'ACTIVE'">
        <v-list-item-title>Version max. autorisée</v-list-item-title>
        <v-list-item-title>{{
            selectedBranch.license.maximumVersionAllowed ? selectedBranch.license.maximumVersionAllowed : 'Aucune restriction'
          }}
        </v-list-item-title>
      </v-list-item>
      <!--Conf-->
      <v-list-group :value="true">
        <template v-slot:activator>
          <v-list-item inactive>
            <v-list-item-avatar>
              <v-icon>settings</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Configuration brute</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item>
          <v-list-item-title class="d-flex align-center">
            <v-text-field hide-details v-model="keyFilter" placeholder="N'afficher que les clés contenant le texte..." outlined prepend-inner-icon="mdi-filter"/>

            <v-btn v-if="logTypesWithHistory.indexOf('CONF')>=0" @click="showHistory('CONF')" icon class="ml-2" :loading="loadingHistory"><v-icon>mdi-history</v-icon></v-btn>
            <v-btn icon class="ml-2" v-else-if="!loadingHistory && errorLoadingHistory" @click="showHistory"><v-icon>mdi-alert-circle-outline</v-icon></v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-for="(v,k) in filteredConfiguration" :key="k">
          <v-list-item-title class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ k }}</span>
              </template>
              <span>{{ k | labelForConfKey }}</span>
            </v-tooltip>
            <v-spacer/>
            <v-btn class="mr-2" x-small icon v-if="confKeyWithHistory.indexOf(k) >= 0" @click="showHistory('CONF', k)"><v-icon>mdi-history</v-icon></v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>{{ v }}</v-list-item-subtitle>
          <v-list-item-action v-if="isUserSupport">
            <v-menu offset-y :close-on-content-click="false" v-if="confKeys[k] && confKeys[k].editable === true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <v-card min-width="400">
                <v-card-subtitle>Modifier&nbsp;<pre style="display: inline-block" class="text--primary">{{k}}</pre></v-card-subtitle>
                <v-card-text>{{k | labelForConfKey}}</v-card-text>
                <v-card-text>
                  <license-conf-key-edition @should-update="$emit('should-update'); fetchHistory();" :branch="selectedBranch" :conf-key="k" :current-value="v"/>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-dialog v-model="showDeactivationDialog" max-width="600">
      <v-card :color="deactivationSelectedChoice === 'TERMINATED' ? 'error' : ''">
        <v-card-title>Désactivation de la licence</v-card-title>
        <v-card-subtitle>{{selectedBranch.name}} — {{selectedBranch.companyName}}</v-card-subtitle>
        <v-card-text><v-select :items="deactivationChoices" v-model="deactivationSelectedChoice"/></v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="showDeactivationDialog=false">Annuler</v-btn>
          <v-spacer/>
          <v-btn color="warning" v-if="deactivationSelectedChoice === 'SUSPENDED'" text @click="deactivateLicense" :loading="deactivationLoading">Suspendre {{selectedBranch.name}}</v-btn>
          <v-btn color="blue lighten-2" v-else-if="deactivationSelectedChoice === 'FROZEN'" text @click="deactivateLicense" :loading="deactivationLoading">Geler {{selectedBranch.name}}<v-icon class="ml-1" small>mdi-snowflake</v-icon></v-btn>
          <v-btn color="white" v-else text @click="deactivateLicense" :loading="deactivationLoading">Désactiver définitivement {{selectedBranch.name}}</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar color="error" v-model="errorDeactivation">Une erreur est survenue</v-snackbar>
    </v-dialog>

    <v-dialog v-model="showHistoryLogsDialog" max-width="800">
      <v-card v-if="historyLogsReportToDisplay">
        <v-card-title class="d-flex">
          {{historyLogsReportToDisplay.type}}
          <v-spacer/>
          <v-btn icon @click="showHistoryLogsDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle v-if="historyLogsReportToDisplay.confKey">{{historyLogsReportToDisplay.confKey}}</v-card-subtitle>
        <v-simple-table>
          <thead>
          <tr>
            <th v-if="historyLogsReportToDisplay.type === 'CONF' && !historyLogsReportToDisplay.confKey">Clé</th>
            <th>Ancien</th>
            <th>Nouveau</th>
            <th>Le</th>
            <th v-if="historyLogsReportToDisplay.type !== 'VERSION'">Par</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(log,i) of historyLogsReportToDisplay.logs" :key="i" style="width: 100%">
            <th v-if="historyLogsReportToDisplay.type === 'CONF' && !historyLogsReportToDisplay.confKey">{{ log.configurationKey }}</th>
            <td>{{log.previousValue}}</td>
            <td>{{log.newValue}}</td>
            <td>{{log.createdAt | moment('L LT')}}</td>
            <td v-if="historyLogsReportToDisplay.type !== 'VERSION'"><y-auth-label :value="log.createdBy"/></td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-dialog>

    <v-snackbar color="error" v-model="error" bottom>Une erreur est survenue</v-snackbar>

  </div>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";
import {mapState} from "vuex";
import LicenseConfKeyEdition from "@/components/LicenseConfKeyEdition";

export default {
  name: "LicenseDetail",
  components: {LicenseConfKeyEdition},
  props: ['selectedBranch'],
  data: () => ({
    // XXX Verrue temporaire pour la colonne Catalina
    error: false,
    errorDeactivation: false,
    catalinaBtnGroup: null,
    catalinaIsLoading: false,
    eaIsLoading: false,
    confKeys: null,
    keyFilter: null,
    showDeactivationDialog: false,
    deactivationLoading: false,
    activationLoading: false,
    deactivationSelectedChoice: 'SUSPENDED',
    deactivationChoices: [{text: 'Suspendre', value: 'SUSPENDED'}, {text: 'Geler (bureau fermé, mais licence encore utilisable)', value: 'FROZEN'}, {text: 'Révoquer définitivement', value: 'TERMINATED'}],

    historyLogs: null,
    loadingHistory: false,
    errorLoadingHistory: false,
    historyLogsReportToDisplay: null,
    showHistoryLogsDialog: false
  }),
  watch: {
    catalinaBtnGroup(newVal) {
      // XXX Verrue temporaire pour la colonne Catalina
      let isCatalina = newVal === 0;
      this.pushCatalina(isCatalina);
    },
    selectedBranch() {
      this.fetchHistory();
    }
  },
  filters: {
    ...LicenseBusiness.filters
  },
  mounted() {
    this.confKeys = LicenseBusiness.getKeysMap();
    this.fetchHistory();
  },
  computed: {
    ...mapState("auth", ['userAuthorisations']),
    selectedBranchIsEa() {
      return !this.selectedBranch ? false : this.selectedBranch.license.conf['ea.actif'] === 'true';
    },
    isUserSupport() {
      if (Array.isArray(this.userAuthorisations)) {
        for (let i = 0; i < this.userAuthorisations.length; i++) {
          if (this.userAuthorisations[i].authorization.role === "admin" || this.userAuthorisations[i].authorization.role === "support" || this.userAuthorisations[i].authorization.role === "root") {
            return true;
          }
        }
        return false;
      } else return false;
    },
    filteredConfiguration() {
      let keyStream = Object.keys(this.selectedBranch.license.conf).filter((k) => k.indexOf("_") < 0); // BranchTable peut rajouter des clés avec des "_", on arrrive pas à les enlever à la source alors on les vire ici
      if (this.keyFilter) {
        let filterToLower = this.keyFilter.toLowerCase();
        keyStream = keyStream.filter((k) => k.indexOf(filterToLower) >= 0 || (this.confKeys[k] && this.confKeys[k].label.toLowerCase().indexOf(filterToLower) >= 0)) // On cherche dans le nom de la clé ET dans la description de la clé (on oublie pas de lowercase tout ça pour pas être embêté par les maj)
      }
      return keyStream
          .reduce((obj, key) => {
            obj[key] = this.selectedBranch.license.conf[key];
            return obj;
          }, {});
    },
    canBeDeactivated() {
      return this.selectedBranch.licenseActivationStatus === 'ACTIVE';
    },
    canBeActivated() {
      return this.selectedBranch.licenseActivationStatus !== 'TERMINATED';
    },
    confKeyWithHistory() {
      return this.historyLogs ? [...new Set(this.historyLogs.filter(l=>l.type === 'CONF').map(l=>l.configurationKey))] : [];
    },
    logTypesWithHistory() {
      return this.historyLogs ? [...new Set(this.historyLogs.map(l=>l.type))] : [];
    }
  },
  methods: {
    toggleEa() {
      if (this.selectedBranch) {
        this.eaIsLoading = true;
        this.error = false;
        let payload = {'ea.actif': this.selectedBranchIsEa ? 'false' : 'true'}; // On envoie l'inverse
        this.$http.post(this.$config.apiUrl + '/v1/branches/' + this.selectedBranch.uuid + '/license/conf-light', payload)
            .then(() => {
              this.fetchHistory();
              this.$emit('should-update');
            }) // On rafraichit les données
            .catch(() => this.error = true).finally(() => this.eaIsLoading = false);
      }
    },
    pushCatalina(isCatalina) {
      if (this.selectedBranch) {
        this.catalinaIsLoading = true;
        this.error = false;
        let payload = {'tmp.catalina': isCatalina ? 'true' : 'false'};
        this.$http.post(this.$config.apiUrl + '/v1/branches/' + this.selectedBranch.uuid + '/license/conf-light', payload)
            .catch(() => this.error = true).finally(() => this.catalinaIsLoading = false);
      }
    },
    deactivateLicense() {
      this.deactivationLoading = true;
      this.errorDeactivation = false;
      this.$http.post(this.$config.apiUrl + '/v1/branches/' + this.selectedBranch.uuid + '/license/activation-status-change-request', {status: this.deactivationSelectedChoice})
          .then(() => {
            this.fetchHistory();
            this.$emit('should-update');
          }) // On rafraichit les données
          .catch(() => this.errorDeactivation = true).finally(()=>{
            this.deactivationLoading=false;
            this.showDeactivationDialog=false;
      })
    },
    activateLicense() {
      this.activationLoading = true;
      this.error = false;
      this.$http.post(this.$config.apiUrl + '/v1/branches/' + this.selectedBranch.uuid + '/license/activation-status-change-request', {status: 'ACTIVE'})
          .then(() => {
            this.fetchHistory();
            this.$emit('should-update');
          }) // On rafraichit les données
          .catch(() => this.error = true).finally(()=>this.activationLoading=false)
    },
    fetchHistory() {
      this.loadingHistory = true;
      this.errorLoadingHistory = false;
      this.historyLogs = null;
      this.$http.get(this.$config.apiUrl + '/v1/branches/' + this.selectedBranch.uuid + '/history-logs')
          .then((r) => this.historyLogs = r.data)
          .catch(() => this.errorLoadingHistory = true)
          .finally(() => this.loadingHistory = false)
    },
    showHistory(type, confKey) {
      if (!this.loadingHistory && this.errorLoadingHistory) {
        alert("Une erreur est survenue lors du chargement de l'historique des modifications de cette agence");
      } else {
        let logs = this.historyLogs.filter(l=>l.type === type && (!confKey || l.configurationKey === confKey)).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
        if (logs.length === 0) {
          alert("Aucun historique pour cette valeur")
        } else {
          this.historyLogsReportToDisplay = {logs, type, confKey};
          this.showHistoryLogsDialog = true;
        }
      }
    }
  }
}
</script>

<style>
.v-data-table {
  max-width: 100%
}
.v-data-table__wrapper {
  max-width: 100%;
  overflow-x: scroll;
}
</style>
