<template>
  <v-container>
    <v-alert type="error" v-if="error">Une erreur est survenue.<span v-if="errorCode">&nbsp;{{errorCodeToText(errorCode)}}</span></v-alert>
    <div v-if="report == null || loading === true">
      <v-btn :loading="loading" @click="fetch" block rounded color="green darken-1" dark>Lancer le calcul</v-btn>
    </div>
    <div v-else-if="error === false">
      <ul>
        <li><a target="_blank" :href="report.axonautOrganisationUrl">Voir l'organisation Axonaut de {{ branch.name }}</a>
        </li>
        <li><a target="_blank" :href="report.axonautMainContractUrl">Voir le contrat socle utilisé</a></li>
        <!--        <li><a target="_blank" :href="report.axonautUsedInvoiceUrl">Voir la facture de référence utilisée</a></li> TODO pour l'instant c'est pété, l'URL retournée par le serveur est mauvaise -->
      </ul>
      <v-row v-for="(inconsistency, idx) in report.inconsistencies" :key="idx" class="mt-4">
        <v-col>
          <invoicing-inconsistency-report-line :inconsistency="inconsistency"/>
        </v-col>
      </v-row>
      <v-row v-if="report.inconsistencies && report.inconsistencies.length === 0">
        <v-alert type="success" class="mt-6" style="width: 100%">
          Aucune incohérence n'a été détectée
        </v-alert>
      </v-row>
    </div>

  </v-container>

</template>

<script>

import InvoicingInconsistencyReportLine from "@/components/InvoicingInconsistencyReportLine";

export default {
  name: "InvoicingInconsistencySingleBranchCard",
  components: {InvoicingInconsistencyReportLine},
  props: ["branch"],
  data: () => ({
    report: null,
    loading: false,
    error: false,
    errorCode: null
  }),
  watch: {
    branch() {
      this.report = null;
      this.error = false;
    }
  },
  methods: {
    fetch() {
      this.error = false;
      this.errorCode = null;
      this.loading = true;
      const currentBranch = this.branch; // Au cas où on aurait changé d'agence pendant le calcul
      this.$http.get(this.$config.apiUrl + "/v1/invoicing/companies/" + this.branch.companyUuid + "/branches/" + this.branch.uuid + "/inconsistencies")
          .then(r => this.report = r.data)
          .catch((r) => {
            this.error = true;
            if (r && r.response && r.response.data && r.response.data.message) this.errorCode = r.response.data.message;
          })
          .finally(() => {
            if (this.branch === currentBranch) { // Au cas où on aurait changé d'agence pendant le calcul
              this.loading = false
            } else {
              this.report = null;
            }
          });
    },
    errorCodeToText(errorCode) {
      switch (errorCode) {
        case "MALFORMED_AXONAUT_ORG_ID": return `L'identifiant d'organisation Axonaut rattaché à la licence est malformé (clé de configuration "support.axonaut.orgid") : [${this.branch.license.conf['support.axonaut.orgid']}].`;
        case "MISSING_AXONAUT_ORG_ID": return "Aucun identifiant d'organisation Axonaut n'est rattaché à cette licence (clé de configuration \"support.axonaut.orgid\").";
        case "ORG_ID_NOT_FOUND_IN_AXONAUT": return `L'identifiant d'organisation Axonaut rattaché à cette licence (${this.branch.license.conf['support.axonaut.orgid']}) est introuable dans Axonaut (clé de configuration "support.axonaut.orgid").`;
        case "NO_CONTRACT_FOUND_AT_ALL_FOR_ORG_ID": return `Aucun contrat n'a été trouvé pour l'organisation Axonaut rattaché à cette licence (${this.branch.license.conf['support.axonaut.orgid']}) (clé de configuration "support.axonaut.orgid").`;
        case "NO_MAIN_CONTRACT_FOUND_FOR_ORG_ID": return `Aucun contrat socle n'a été trouvé pour l'organisation Axonaut rattaché à cette licence (${this.branch.license.conf['support.axonaut.orgid']}) (clé de configuration "support.axonaut.orgid").`;
        case "MULTIPLE_MAIN_CONTRACT_FOUND": return `Plusieurs contrats socle ont été trouvés pour l'organisation Axonaut rattaché à cette licence (${this.branch.license.conf['support.axonaut.orgid']}) (clé de configuration "support.axonaut.orgid").`;
        case "NO_SUBSCRIPTION_INVOICE_FOUND_INSIDE_MAIN_CONTRACT": return `Aucune facture contenant des lignes de produits liés à un abonnement Yodaforex n'a été trouvé dans le contrat socle pour l'organisation Axonaut rattaché à cette licence (${this.branch.license.conf['support.axonaut.orgid']}) (clé de configuration "support.axonaut.orgid").`;
        case "ERROR_REQUESTING_AXONAUT": return `Erreur lors d'un appel à Axonaut. Peut-être que l'identifiant d'organisation Axonaut à cette licence (${this.branch.license.conf['support.axonaut.orgid']}) est erroné (clé de configuration "support.axonaut.orgid").`;
        default: return errorCode;
      }
    }
  }
}
</script>

