<template>
  <v-container>
    <v-alert type="error" v-if="unknownError">Une erreur est survenue. Cette société a-t-elle un cloud ?</v-alert><!-- TODO gérer correctement les erreurs de société sans cloud -->
    <v-alert type="warning" v-else-if="noCloudError">Cette société ne semble pas avoir de cloud, ou sa configuration
      n'est identique entre toutes ses agences.
    </v-alert>
    <v-row v-else-if="!result">
      <v-col>
        <v-btn :loading="loading" @click="fetch" block rounded color="green darken-1" dark>Lancer le calcul</v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Table</th>
              <th class="text-left">Nb. enreg.</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(v,t) in result" :key="t">
              <td>
                <pre>{{ t }}</pre>
              </td>
              <td>
                <pre>{{ v }}</pre>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr class="blue-grey lighten-3">
              <th>Total</th>
              <td>
                <pre>{{ total }}</pre>
              </td>
            </tr>
            <tr class="lime lighten-3">
              <th>Total à facturer</th>
              <td>
                <pre>{{ totalToBill }}</pre>
              </td>
            </tr>
            <tr class="lime lighten-4">
              <th>Nb. de pack de 100 000 lignes à facturer</th>
              <td>
                <pre>{{ totalOfProductLignesToBill }}</pre>
              </td>
            </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <cloud-consult-reset-quota-button :company-uuid="company.uuid"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CloudConsultResetQuotaButton from "@/components/CloudConsultResetQuotaButton";

export default {
  name: "CloudUsage",
  components: {CloudConsultResetQuotaButton},
  props: ['company'],
  data: () => ({
    currentCompany: null,
    result: null,
    loading: false,
    unknownError: false,
    noCloudError: false,
  }),
  mounted() {
    this.currentCompany = this.company;
    this.fetch();
  },
  watch: {
    company(v) {
      this.currentCompany = v;
      this.loading = false;
      this.unknownError = false;
      this.noCloudError = false;
      this.result = null;
    }
  },
  computed: {
    total() {
      return this.result ? Object.values(this.result).reduce((a,b) => a + b, 0) : -1;
    },
    totalToBill() {
      return this.result ? this.result['Bank_line'] + this.result['Customer'] + this.result['Invoice_line'] + this.result['Product'] : -1;
    },
    totalOfProductLignesToBill() {
      let packToBill = Math.floor(this.totalToBill / 100000);
      return (this.totalToBill > 0 && packToBill === 0) ? 1 : packToBill; // Si on est dans la tranche 0 -> 100 000, on facture 1 pack, si on est dans la tranche 100 000 -> 200 000 aussi.
    }
  },
  methods: {
    fetch() {
      const company = this.currentCompany;
      this.loading = true;
      this.unknownError = false;
      this.noCloudError = false;
      this.$http.get(this.$config.apiUrl + "/v1/invoicing/cloud/companies/" + company.uuid)
          .then(r => {
            if (this.currentCompany === company) { // Au cas où la société aurait changé depuis le début du calcul
              this.result = r.data;
            }
          })
          .catch(e => {
            if (e.response && e.response.status === 400) {
              this.noCloudError = true;
            } else {
              this.unknownError = true;
            }
          })
          .finally(() => {
            if (this.currentCompany === company) { // Au cas où la société aurait changé depuis le début du calcul
              this.loading = false;
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
