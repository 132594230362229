import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VersionView from '../views/VersionView.vue'
import InvoicingView from "../views/InvoicingView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: route => ({ q: route.query.q }),
    children: [
      {
        path: '/branches/:branchUuid',
        name: 'Branch',
        component: Home,
      }
    ]
  },
  {
    path: '/versions',
    name: 'Versions',
    component: VersionView
  },
  {
    path: '/invoicing',
    name: 'Invoicing',
    component: InvoicingView
  }
]

const router = new VueRouter({
  routes
})

export default router
