module.exports = {
  env: 'production',
  apiUrl: '',
  authConfig: {},
  services: {
    consultApi: {
      url: 'https://consult.api.yodaforex.cloud',
      identifier: 'cloud-consult'
    }
  }
}
