<template>
  <v-form @submit.prevent="submit" :disabled="loading">
    <v-snackbar v-model="error" color="error" :timeout="3000">Une erreur est survenue</v-snackbar>
    <license-conf-key-input v-model="userVal" :conf-key="confKey"/>
    <v-btn type="submit" block text :loading="loading" color="primary" :disabled="!modified">
      Sauvegarder
    </v-btn>
  </v-form>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";
import LicenseConfKeyInput from "@/components/LicenseConfKeyInput";

export default {
  name: "LicenseConfKeyEdition",
  components: {LicenseConfKeyInput},
  props: ['branch', 'confKey', 'currentValue'],
  data: () => ({
    userVal: null,
    originalValue: null,

    loading: false,
    error: false
  }),
  mounted() {
    this.updateValue(this.currentValue);
  },
  watch: {
    currentValue(val) {
      this.updateValue(val);
    }
  },
  computed: {
    type() {
      if (this.confKey) {
        const keyProperties = LicenseBusiness.getKeysMap()[this.confKey];
        if (keyProperties && keyProperties.type) {
          return keyProperties.type;
        } else return "string";
      } else return null;
    },
    selectItems() {
      if (this.confKey) {
        const keyProperties = LicenseBusiness.getKeysMap()[this.confKey];
        if (keyProperties && keyProperties.type && keyProperties.type === 'enum') {
          return keyProperties.values;
        } else return [];
      } else return null;
    },
    modified() {
      return this.userVal !== this.originalValue;
    }
  },
  methods: {
    updateValue(val) {
      if (this.type === 'boolean') {
        this.userVal = val === 'true';
      } else if (this.type === 'number') {
        this.userVal = Number.parseFloat(val)
      } else {
        this.userVal = val;
      }
      this.originalValue = this.userVal;
    },
    submit() {
      this.error = false;
      this.loading = true;
      let payload = {};
      payload[this.confKey] = this.userVal;
      this.$http.post(this.$config.apiUrl + '/v1/branches/' + this.branch.uuid + '/license/conf-light', payload)
          .then(() => this.$emit('should-update'))
          .catch(() => this.error = true).finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
