<template>
  <div class="d-flex flex-column align-center">
  <slot v-bind:activator="{on: {click: onClick}, loading: loading, error: error, text: text}">
    <v-btn block outlined rounded :loading="loading" :color="error ? 'red lighten-4' : 'primary'" :disabled="error" v-on="{click: onClick}">{{ text }}</v-btn>
  </slot>
    <span class="caption">
      <span v-if="usage">
        Conso {{usage.consumed ? usage.consumed : 0}}/{{usage.availablePerDay}}ms — Dernier appel : <span v-if="usage.lastSeenAt">{{usage.lastSeenAt | moment('lll')}}</span><span v-else>n/a</span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "CloudConsultResetQuotaButton",
  props: {
    companyUuid: String
  },
  data: () => ({
    loading: false,
    error: false,
    usage: null
  }),
  computed: {
    text() {
      return this.usage && !this.error ? 'Réinitialiser le quota Cloud Consult API' : "Cloud Consult - Impossible d'accéder au service";
    }
  },
  mounted() {
    this.getUsage();
  },
  watch: {
    companyUuid() {
      this.getUsage();
    }
  },
  methods: {
    getUsage() {
      this.loading = true;
      this.error = false;
      this.$http.get(`${this.$config.services.consultApi.url}/v1/admin/access-control/${this.companyUuid}/status`)
          .then((r) => this.usage = r.data)
          .catch(() => this.error = true).finally(() => this.loading = false)
    },
    onClick() {
      this.loading = true;
      this.error = false;
      this.$http.post(`${this.$config.services.consultApi.url}/v1/admin/access-control/${this.companyUuid}/reset-counter`)
          .then(() => this.getUsage())
          .catch(() => this.error = true).finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
