<script>
export default {
  name: "KryptoLicenseUpdateButton",
  data: () => ({
    success: false,
    loading: false,
    error: false,
    impactedLicencesCount: 0,
  }),
  methods: {
    send() {
      this.error = false;
      this.loading = true;
      this.success = false;
      this.$http.post(this.$config.apiUrl + "/v1/branches/fill-incompletes")
          .then((e) => {
            this.impactedLicencesCount = e.data.impactedLicencesCount
            this.success = true;
          })
          .catch(() => this.error = true)
          .finally(() => this.loading = false);
    }
  }
}
</script>

<template>
  <v-btn text block @click="send" :loading="loading" :color="error ? 'error' : success ? 'success' : ''">
    <template v-if="error">Une erreur est survenue</template>
    <template v-else-if="success">
      <template v-if="impactedLicencesCount === 0">Toute les licences sont déjà à jour</template>
      <template v-else-if="impactedLicencesCount > 1">{{impactedLicencesCount}} licences ont été mises à jour</template>
      <template v-else>1 licence a été mise à jour</template>
    </template>
    <template v-else>Mettre à jour les licences Krypto</template>
  </v-btn>
</template>

<style scoped>

</style>
